<template>
  <v-container :fluid="fluid" style="padding: 100px 0 64px 0; min-height: 90vh;">
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  props: {
    fluid: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({})
}
</script>
